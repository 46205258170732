<template>
  <fieldset class="fieldset-component">
    <legend class="legend" :title="title">
      <span class="legend-title" :style="{ fontSize: titleFontSize }">{{
        title
      }}</span>
      <span class="legend-sub-title" v-if="subTitle">（{{ subTitle }}）</span>
    </legend>
    <slot></slot>
  </fieldset>
</template>
  
<script>
export default {
  name: "fieldSet",
  props: {
    title: {
      type: String,
      require: true,
    },
    subTitle: {
      type: String,
      default: "",
    },
    titleFontSize: {
      type: String,
      default: "16px",
    },
  },
  data() {
    return {};
  },
};
</script>
  
<style lang="scss">
.fieldset-component {
  border: 1px solid #ccc;
  margin-bottom: 10px;
  padding: 5px 10px;
  .legend {
    padding: 8px;
    .legend-title {
      font-weight: bold;
    }
    .legend-sub-title {
      color: #666;
      font-size: 14px;
    }
  }
}
</style>
  

<!-- <template>
  <div>
    <field-set title="表达式" titleFontSize="20px">
      <el-form> V </el-form>
    </field-set>
  </div>
</template>
<script>
import FieldSet from "@/views/tool/FieldSet.vue";
export default {
  components: {
    FieldSet,
  },
};
</script> -->
